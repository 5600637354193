//
// Mixins / Plugins / Bootstrap-datepicker
// --------------------------------------------------

@mixin datepicker-theme($color, $background) {
  .datepicker {
    thead th { color: $color; }

    .datepicker-switch,
    .prev,
    .next {
      background: $background;

      &:hover { background: lighten($background, 3%); }
    }

    .dow,
    thead .cw {
      border-top: 1px solid darken(desaturate($background, 20%), 10%);

      background: darken(desaturate($background, 20%), 5%);
    }

    .active,
    .range-start,
    .range-end {
      &,
      &:hover {
        color: $color !important;
        background: $background !important;
      }
    }
  }
}
